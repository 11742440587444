<template>
  <div id="featured">
    <div class="row">
      <div class="col-6 my-auto">
        <p class="mb-0">Music Albums</p>
      </div>
      <div class="col-6 my-auto">
        <FilterBar :list="'album'"></FilterBar>
      </div>
    </div>
    <vuetable ref="vuetable"
              data-path="featuredAlbums"
              pagination-path="pagination"
              track-by="mobile_album_id"
              :api-url="apiUrl"
              :fields="fields"
              :http-options="httpOptions"
              :css="css.table"
              :per-page="10"
              :append-params="moreParams"
              :query-params="{
                sort: 'sort',
                page: 'page',
                perPage: 'perPage'}"
              @vuetable:pagination-data="onPaginationData">
      <template slot="images" slot-scope="props">
        <img :src="props.rowData.thumbnail"
             alt="album-thumbnail"
             width="60"
             height="60" />
      </template>
      <template slot="action-item" slot-scope="props">
        <button class="btn btn-sm btn-success"
                type="button"
                :disabled="props.rowData.featured === true"
                v-if="props.rowData.featured === true">
          Added
        </button>

        <button class="btn btn-sm btn-outline-success"
                @click="addFeatured(props.rowData)"
                type="button"
                v-else>
          <i class="fas fa-plus"></i> Add
        </button>
      </template>
    </vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination"
                           @vuetable-pagination:change-page="onChangePage"
                           :css="css.pagination">
      </vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import TableStyle from '../table-bootstrap-css.js';

  const FilterBar =
    () => import('@/components/admin/featured_album/FilterBar.vue');
  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');

  export default {
    name  : 'FeaturedAlbumList',
    props : {
      update : Boolean,
    },
    data() {
      return {
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/albums',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [ {
          name       : 'mobile_album_id',
          title      : 'Album<br>ID',
          sortField  : 'mobile_album_id',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:images',
          title      : 'Thumbnail',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name      : 'album_name',
          title     : 'Album Name',
          sortField : 'album_name',
          direction : 'desc',
        }, {
          name       : 'no_of_tracks',
          title      : 'No of tracks',
          sortField  : 'no_of_tracks',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:action-item',
          title      : 'Action',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        } ],
      }
    },
    components : {
      FilterBar,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    watch : {
      update(value) {
        if (value) {
          this.$refs.vuetable.refresh();
          this.$emit('update-album-table');
        }
      },
    },
    methods : {

      transform(data) {
        const transformed = {};

        if (data.data.length === 0 && data.currentPage > 1) {
          // redirect to previous page
          this.onChangePage(data.currentPage - 1);
        } else {
          transformed.pagination = {
            'total'         : data.total,
            'per_page'      : data.perPage,
            'current_page'  : data.currentPage,
            'last_page'     : data.lastPage,
            'next_page_url' : data.nextPageUrl,
            'prev_page_url' : data.prevPageUrl,
            'from'          : data.from,
            'to'            : data.to,
          };

          transformed.featuredAlbums = [];

          for (let i = 0; i < data.data.length; i++) {
            transformed.featuredAlbums.push({
              'mobile_album_id' : data.data[i].item.mobile_album_id,
              'album_name'      : data.data[i].item.album_name,
              'thumbnail'       : data.data[i].item.album_thumbnail,
              'no_of_tracks'    : data.data[i].item.no_of_tracks,
              'featured'        : (data.data[i].item.featured == 1) ?
                true : false,
            });
          }
        }

        return transformed;
      },

      /**
       * Add Featured Album
       * @param data
       */
      addFeatured(data) {
        this.$modal.show('dialog', {
          title : 'Add Featured Album',
          text  : 'Are you sure you want to feature <b>' + data.album_name +
            '</b>?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.post('api/albums/featured', {
                albumId : data.mobile_album_id,
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Added ' + data.album_name + ' to Featured List!',
                });
                this.$modal.hide('dialog');
                this.reloadTable();
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              })
            },
          }, {
            title : 'No',
          } ],
        })
      },

      /**
       * Reload Table
       */
      reloadTable() {
        this.$refs.vuetable.reload(); // reload vuetable
        this.$emit('update-featured-table');
      },

      /**
       * Set Pagination Info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Change page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Filter Set
       *
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * On Filter Reset
       */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },
    },
    mounted() {
      this.$events.$on('filter-set-album', eventData =>
        this.onFilterSet(eventData));
      this.$events.$on('filter-reset-album', () => this.onFilterReset());
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../assets/scss/components/admin/featured_album/mobile-albums";
</style>